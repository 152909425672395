<template>
<div>
  
                        

      <div class="rounded-lg shadow border border-white bg-white">
            <div class="row justify-content-centre align-items-center">   

            <div class="col">
            <h4 class=" font-weight-lighter text-info m-3 float-left"> <span class="fa fa-users"></span> Clients    </h4> 

                <b-button pill size="sm"  class="mt-3 mb-3 float-left" variant="outline-success" @click="add_click($event.target)">
                            <i class="fa fa-plus-square"></i>
                    <span> Ajouter</span> 
                </b-button>


                <b-button pill size="sm"  class="ml-3 mt-3 mb-3 float-left" variant="outline-primary" @click="exportDox">
                            <i class="fa fa-print"></i>
                    <span> Impr</span> 
                </b-button>

            </div>

            
            <div class="col">
                <div class="input-group col">
                                    <select class="form-control border border-ligth " id="selected_zone" v-model="selected_zone">
                                            <option v-for="item in zones" :key="item.id" v-bind:value="item.id">   
                                                {{ item.nom }}
                                            </option>
                                            <option value=0>Tous</option>

                                     </select>


                                    <div class="input-group-prepend">
                                        <button @click="click_ClientList" class="btn  btn-light  border border-ligth rounded-right " type="submit" id="button-addon1">   
                                        <span class="fa fa-search"></span> Liste
                                        </button>
                                    </div>

                </div>
            </div>
              <b-form-input v-model="filter" type="search" id="filterInput" placeholder="Rechercher" class="col ml-4 mr-4"></b-form-input>


            </div>
       



        <b-table 
        id="main-table"
        sort-icon-left
        responsive  
        head-variant="light"
        :filter="filter"
        :per-page="perPage"
        :current-page="currentPage"
        :fields="fields" 
        :items="itemslist"
        >
            <template #cell(actions)="row">
                    <b-button pill size="sm"  class="mr-2 mt-1 mb-1 float-right" variant="outline-danger" @click="del_click(row.item)">
                                <i class="fa fa-trash-alt"></i>
                        
                    </b-button>
                    <b-button pill size="sm" class="mr-2 mt-1 mb-1 float-right" variant="outline-primary" @click="edit_click(row.item, row.index, $event.target)">
                            <i class="fa fa-pencil-alt"></i>
                    
                    </b-button>
                </template>
        
        </b-table>
                <b-pagination
                pills
                v-model="currentPage"
                :total-rows="rows_pagin"
                :per-page="perPage"
                aria-controls="main-table"
                align="center"
                ></b-pagination>

      </div>


    <!-- le modal -->
    <b-modal id="banqModal" :title="itemForm.nom_prenom" size="lg" hide-footer>
           <form @submit.prevent="handleSubmit">
            <div class="row">
            <div class="form-group col">
                <label for="code">Code Client :</label>
                <input type="text" v-model="itemForm.code" id="code" name="code" class="form-control"
                    :class="{ 'is-invalid': isSubmitted && $v.itemForm.code.$error }" />
                <div v-if="isSubmitted && !$v.itemForm.code.required" class="invalid-feedback">Ce champ est obligatoire !!</div>
            </div>


            <div class="form-group col">
                <label for="cin">CIN :</label>
                <input type="text" v-model="itemForm.cin" id="name" name="cin" class="form-control"
                    :class="{ 'is-invalid': isSubmitted && $v.itemForm.cin.$error }" />
                <div v-if="isSubmitted && !$v.itemForm.cin.required" class="invalid-feedback">Ce champ est obligatoire !!</div>
            </div>
        </div>
        <div class="row">



            <div class="form-group col">
                <label for="nom_prenom">Nom et Prénom :</label>
                <input type="text" v-model="itemForm.nom_prenom" id="name" name="nom_prenom" class="form-control"
                    :class="{ 'is-invalid': isSubmitted && $v.itemForm.nom_prenom.$error }" />
                <div v-if="isSubmitted && !$v.itemForm.nom_prenom.required" class="invalid-feedback">Ce champ est obligatoire !!</div>
            </div>

            <div class="form-group col">

                            <label for="itemForm">Zone :</label>
                            <select class="form-control" id="itemForm" v-model="itemForm.zone_id"
                            :class="{ 'is-invalid': isSubmitted && $v.itemForm.zone_id.$error }">
                            <option v-for="item in zones" :key="item.id" v-bind:value="item.id">   
                                {{ item.nom }}
                            </option>

                            </select>

            </div>
                </div>

                <div class="row">


                <div class="form-group col">
                    <label for="tel">Profession :</label>
                    <input type="text" v-model="itemForm.profession" id="tel" name="tel" class="form-control" />

                </div>

                <div class="form-group col">
                    <label for="tel">Téléphone :</label>
                    <input type="text" v-model="itemForm.tel" id="tel" name="tel" class="form-control" />

                </div>


                </div>

            <div class="row">

                        <div class="form-group col">
                            <label for="ice">ICE :</label>
                            <input type="text" v-model="itemForm.ice" id="ice" name="ice" class="form-control" />
                        </div>

                        
                        <div class="form-group col">
                            <label for="garant">Garant(e) :</label>
                            <input type="text" v-model="itemForm.garant" id="garant" name="garant" class="form-control" />

                        </div>
            </div>



            <div class="row">

                <div class="form-group col">
                <label for="Description">Adresse :</label>
                <textarea name="description" id="description"  rows="2" class="form-control" v-model="itemForm.adresse"></textarea>
                </div>


                <div class="form-group col">
                <label for="garant_infos">Garant(e) Infos:</label>
                <textarea name="garant_infos" id="garant_infos"  rows="2" class="form-control" v-model="itemForm.garant_infos"></textarea>
                </div>

            </div>


            <div class="row">
                


                <div class="form-group col">
                <div class="custom-control custom-switch">
                <input type="checkbox"
                 class="custom-control-input" 
                 id="is_plaf"
                        v-model="itemForm.is_plaf"
                        name="is_plaf"
                        value=true
                        unchecked-value=false
    
                 >
    
                <label class="custom-control-label" for="is_plaf">Activer Le Plafond</label>
                </div>
                </div>
    
                <div v-if="itemForm.is_plaf" class="form-group col">
                    <label for="plafond">Plafond</label>
                    <input type="text" v-model="itemForm.plafond" id="plafond" name="plafond" class="form-control"
                        :class="{ 'is-invalid': isSubmitted && $v.itemForm.plafond.$error }" />
                    <div v-if="isSubmitted && !$v.itemForm.plafond" class="invalid-feedback">Ce champ est obligatoire !!</div>
                    <div v-if="isSubmitted && !$v.itemForm.plafond" class="invalid-feedback">Entrez une valeur numérique !!</div>
                </div>
            </div>
    


            <div class="custom-control custom-switch">
            <input type="checkbox"
             class="custom-control-input" 
             id="etat"
                    v-model="itemForm.etat"
                    name="etat"
                    value=true
                    unchecked-value=false

             >

            <label class="custom-control-label" for="etat">Actif</label>
            </div>

            <div class="form-group mt-3">
                <button type="submit" class="btn  btn-primary btn-block">{{ModalType}}</button>
            </div>


        </form>
    </b-modal>


    <loading :active.sync="isLoading" :can-cancel="false" :is-full-page="true" color="#0179F9" loader='bars'></loading>


</div>
</template>

<script>
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import {required,decimal} from "vuelidate/lib/validators";
import { HeadingLevel,PageNumber, PageNumberFormat,Footer, Header, Table,TableCell,TableRow,Document, Paragraph,WidthType,VerticalAlign,AlignmentType, Packer, TextRun } from "docx";
import { saveAs } from 'file-saver';

export default {
    computed: {
                          entet_pag() {

      if(this.$store.getters.get_entete)
      {
            return this.$store.getters.get_entete;
      }
      else
      {
            return '';
      }
    },
    sousentet_pag() {

      if(this.$store.getters.get_sousentete)
      {
            return this.$store.getters.get_sousentete;
      }
      else
      {
            return '';
      }
    },
    pied_pag() {

      if(this.$store.getters.get_pied)
      {
            return this.$store.getters.get_pied;
      }
      else
      {
            return '';
      }
    },
      rows_pagin() {
        return this.itemslist.length
      }
    },
    mounted(){

        this.fetchFormZones()
    },
	data () {
		return {
            filter: null,
            perPage: 10,
            currentPage: 1,
            selected_zone:'',
            itemslist: [],
            zones:[],
            itemForm: {
                    id:'',
                    code:'',
                    cin:'',
                    nom_prenom:'',
                    tel:'',
                    etat:true,
                    adresse:'',
                    ice:'',
                    profession:'',
                    garant:'',
                    garant_infos:'',
                    is_plaf:false,
                    plafond:0,
                    zone_id:''},
            fields: [
                {key: 'code', label: 'Code/ID', sortable: true},
                {key: 'cin', label: 'CIN', sortable: true},
                {key: 'nom_prenom', label: 'Nom et Prénom', sortable: true},
                {key: 'zone', label: 'Zone', sortable: true},
                {key: 'tel', label: 'Téléphone', sortable: true},
                {key: 'profession', label: 'Profession', sortable: true},
                {key: 'garant', label: 'Garant(e)', sortable: true},
                {key: 'is_plaf', label: 'Plaf.Actif', 
                formatter: (value) => {
                 return value ? 'Oui' : 'Non'
                }, sortable: true},
                {key: 'plafond', label: 'Plafond', sortable: true},
                {key: 'etat', label: 'Actif', 
                formatter: (value) => {
                 return value ? 'Oui' : 'Non'
                }, sortable: true},
                { key: 'actions', label: '' }
            ],
            //public trucs
            ModalEdit: false,
            ModalType:'Ajouter',
            isSubmitted: false,
            isLoading: false
		}
	},
    validations: {
            itemForm: {
                code: {
                    required
                },
                cin: {
                    required
                },
               nom_prenom: {
                    required
                },
                zone_id: {
                    required
                },
                is_plaf: {
                    required
                },
                plafond: {
                    required,
                    decimal
                },
                etat: {
                    required
                }
            }
    },
    methods :{


        exportDox()
                {

                let cent = ""


                if(this.selected_zone==0)
                {
                cent="Tous"
                }
                else
                {
                let mm=this.zones.findIndex(item => item.id === this.selected_zone)

                cent= this.zones[mm].nom
                }



                let datrw =[new TableRow({
                            children: [
                                new TableCell({
                                    width: {
                                        size: 1000,
                                        type: WidthType.DXA,
                                    },
                                    verticalAlign: VerticalAlign.CENTER,
                                    children: [new Paragraph({
                                                text: "Code / ID",
                                                    spacing: {
                                                        before: 100,
                                                        after: 100
                                                    },
                                                heading: HeadingLevel.HEADING_3,
                                                alignment: AlignmentType.CENTER,
                                            })],
                                }),


                                new TableCell({
                                    width: {
                                        size: 3000,
                                        type: WidthType.DXA,
                                    },
                                    verticalAlign: VerticalAlign.CENTER,
                                    children: [new Paragraph({
                                                text: "Nom et Prénom",
                                                heading: HeadingLevel.HEADING_3,
                                                alignment: AlignmentType.CENTER,
                                            })],
                                }),

                                new TableCell({
                                                        width: {
                                                            size: 2000,
                                                            type: WidthType.DXA,
                                                        },
                                    verticalAlign: VerticalAlign.CENTER,
                                    children: [new Paragraph({
                                                text: "Zone",
                                                heading: HeadingLevel.HEADING_3,
                                                alignment: AlignmentType.CENTER,
                                            })],
                                }),
                                
                                new TableCell({
                                    width: {
                                        size: 2000,
                                        type: WidthType.DXA,
                                    },
                                    verticalAlign: VerticalAlign.CENTER,
                                    children: [new Paragraph({
                                                text: "Tél",
                                                heading: HeadingLevel.HEADING_3,
                                                alignment: AlignmentType.CENTER,
                                            })],
                                }),
                                                new TableCell({
                                    width: {
                                        size: 4000,
                                        type: WidthType.DXA,
                                    },
                                    verticalAlign: VerticalAlign.CENTER,
                                    children: [new Paragraph({
                                                text: "Adresse",
                                                heading: HeadingLevel.HEADING_3,
                                                alignment: AlignmentType.CENTER,
                                            })],
                                }),

                            ],
                        })]

                this.itemslist.forEach(function(item){

                let r = new TableRow({
                            children: [
                                new TableCell({
                                    width: {
                                        size: 1000,
                                        type: WidthType.DXA,
                                    },
                                    verticalAlign: VerticalAlign.CENTER,
                                    children: [new Paragraph({
                                                text: item.code,
                                                spacing: {
                                                before: 50,
                                                after: 50
                                            },
                                                heading: HeadingLevel.HEADING_3,
                                                alignment: AlignmentType.CENTER,
                                            })],
                                }),

                                new TableCell({
                                    width: {
                                        size: 3000,
                                        type: WidthType.DXA,
                                    },
                                    verticalAlign: VerticalAlign.CENTER,
                                    children: [new Paragraph({
                                                text: item.nom_prenom,
                                                heading: HeadingLevel.HEADING_3,
                                                alignment: AlignmentType.CENTER,
                                            })],
                                }),

                                new TableCell({
                                    width: {
                                        size: 2000,
                                        type: WidthType.DXA,
                                    },
                                    verticalAlign: VerticalAlign.CENTER,
                                    children: [new Paragraph({
                                                text: item.zone,
                                                heading: HeadingLevel.HEADING_3,
                                                alignment: AlignmentType.CENTER,
                                            })],
                                }),

                                new TableCell({
                                    width: {
                                        size: 2000,
                                        type: WidthType.DXA,
                                    },
                                    verticalAlign: VerticalAlign.CENTER,
                                    children: [new Paragraph({
                                                text: item.tel,
                                                heading: HeadingLevel.HEADING_3,
                                                alignment: AlignmentType.CENTER,
                                            })],
                                }),


                                new TableCell({
                                    width: {
                                        size: 4000,
                                        type: WidthType.DXA,
                                    },
                                    verticalAlign: VerticalAlign.CENTER,
                                    children: [new Paragraph({
                                                text: item.adresse,
                                                heading: HeadingLevel.HEADING_3,
                                                alignment: AlignmentType.CENTER,
                                            })],
                                }),


                            ],
                        })

                datrw.push(
                r
                )
                });



                const table = new Table({

                    rows:  datrw
                })


                const doc = new Document({

        sections: [
            {
                properties: {
                                page: {
                                    pageNumbers: {
                                        start: 1,
                                        formatType: PageNumberFormat.DECIMAL,
                                    },
                                    margin:{
                                        left:500,
                                        right:500
                                    }
                                },
                            },
                            headers: {
                                default: new Header({
                                    children: [
                                new Paragraph({
                                            children: [
                                    new TextRun({
                                        text: this.entet_pag,
                                        color: '636572',
                                        bold: true,

                                        }),
                                            ],
                                            heading: HeadingLevel.TITLE,
                                            alignment: AlignmentType.CENTER,
                                        }),

                                new Paragraph({
                                            children: [
                                    new TextRun({
                                        text: this.sousentet_pag,
                                        color: '636572',
                                        bold: false,
                                        })

                                            ],
                                        border: {
                                        bottom: {
                                                color: "636572",
                                                space: 4,
                                                value: "single",
                                                size: 10,
                                            }},
                                            heading: HeadingLevel.HEADING_1,
                                            alignment: AlignmentType.CENTER,
                                        }),




                                    ],
                                }),
                            },
                            footers: {
                                default: new Footer({
                                    children: [

                                new Paragraph({
                                            children: [
                                    new TextRun({
                                        text: this.pied_pag,
                                        color: '636572',
                                        bold: false,
                                        })

                                            ],
                                        border: {
                                        top: {
                                                color: "636572",
                                                space: 4,
                                                value: "single",
                                                size: 10,
                                            }},
                                            heading: HeadingLevel.HEADING_3,
                                            alignment: AlignmentType.CENTER,
                                        }),

                                        new Paragraph({
                                            alignment: AlignmentType.RIGHT,
                                            children: [
                                                new TextRun({
                                                    children: ["Page : ", PageNumber.CURRENT],
                                                    color: '636572'
                                                }),
                                                new TextRun({
                                                    children: [" Sur ", PageNumber.TOTAL_PAGES],
                                                    color: '636572'
                                                }),
                                            ],
                                        }),
                                    ],
                                }),
                            },



                            children: [

                                new Paragraph({



                                    children: [

                                        new TextRun({
                                        text: "Liste Des Clients",
                                        color: '636572',
                                        bold: false,
                                        font: "Segoe UI Light"
                                    }), 

                                        new TextRun({
                                        text: "     Zone : "+cent,
                                        color: '636572',
                                        bold: false,
                                        font: "Segoe UI Light"
                                    })

                                    ],

                                    heading: HeadingLevel.HEADING_2,
                                                        alignment: AlignmentType.CENTER,

                                        spacing: {
                                            after: 300,
                                            before: 300
                                        },
                                }),




                                table
                                
                                ]

            }]

        });

                    Packer.toBlob(doc).then(blob => {

                saveAs(blob, "Liste_clients.docx");

                    });

                },

        click_ClientList()
        {

                                if(this.selected_zone)
                                {
                            this.isLoading=true;
                            this.$http.get('/listClients/'+this.selected_zone)
                            .then((result) => {
                                        this.itemslist = result.data.data

                                        console.log(result.data.data)
                                        this.isLoading=false;
                            })
                            .catch(error=> {
                                        console.log(error)
                                        this.isLoading=false;
                                        this.flashMessage.show({
                                        status: 'error',
                                        title: 'Erreur de Chargement',
                                        message: 'Impossible charger les données.. Actualisez et Réessayez'
                                        })
                            });
                                }
                                else
                                {
                                                this.flashMessage.show({
                                                status: 'info',
                                                title: 'Sélectionez une zone et Reésayez !!',
                                                })
                                }


        },

        itemslisttore(){
                        this.isLoading=true;

            this.$http.post('/clients',{
            code:this.itemForm.code,
            cin:this.itemForm.cin,
            nom_prenom:this.itemForm.nom_prenom,
            tel:this.itemForm.tel,
            adresse:this.itemForm.adresse,
            ice:this.itemForm.ice,
            etat:this.itemForm.etat,
            profession:this.itemForm.profession,
            garant:this.itemForm.garant,
            garant_infos:this.itemForm.garant_infos,
            is_plaf:this.itemForm.is_plaf,
            plafond:this.itemForm.plafond,
            zone_id:this.itemForm.zone_id


                })
                .then(response => {

                    this.isLoading=false;
                    this.itemslist.unshift(response.data.data);
                    this.$bvModal.hide('banqModal')

                    //afficher message
                    this.flashMessage.show({
                    status: 'success',
                    title: 'Données sauvegardées',
                    message: 'Les données sont sauvegardées correctement '
                })


                })
                .catch(error=> {console.log(error)
                this.isLoading=false;


                                this.flashMessage.show({
                status: 'error',
                title: 'Erreur De Sauvegarde',
                message: 'Impossible  de sauvegarder les données .. Réessayez !!'
                })
                });

        },
        banqueEdit(){

            this.isLoading=true;

            this.$http.patch('/clients/'+this.itemForm.id,{
            code:this.itemForm.code,
            cin:this.itemForm.cin,
            nom_prenom:this.itemForm.nom_prenom,
            tel:this.itemForm.tel,
            adresse:this.itemForm.adresse,
            ice:this.itemForm.ice,
            etat:this.itemForm.etat,
            profession:this.itemForm.profession,
            garant:this.itemForm.garant,
            garant_infos:this.itemForm.garant_infos,
            is_plaf:this.itemForm.is_plaf,
            plafond:this.itemForm.plafond,
            zone_id:this.itemForm.zone_id

                })
                .then(response => {

                    this.isLoading=false;
                    console.log(response.data)
                    this.$bvModal.hide('banqModal')

                    let index = this.itemslist.findIndex(item => item.id === this.itemForm.id)
                    this.itemslist.splice(index, 1,response.data.data)
                    
                    this.flashMessage.show({
                    status: 'success',
                    title: 'Données modifiées',
                    message: 'Les données sont modifiées correctement'
                    })


                    })
                    .catch(error=> {console.log(error)
                    this.isLoading=false;


                    this.flashMessage.show({
                    status: 'error',
                    title: 'Erreur De Modification',
                    message: 'Impossible  de modifier les données .. Réessayez !!'
                })
                });

        },
        banqueDell(){

            this.isLoading=true;

            this.$http.delete('/clients/'+this.itemForm.id)
                .then(response => {
                console.log(response.data)
                    this.isLoading=false;



                    
                this.flashMessage.show({
                status: 'success',
                title: 'Données Supprimées',
                message: 'Les données sont supprimées correctement'
                })


                    let index = this.itemslist.findIndex(item => item.id === this.itemForm.id)
                    this.itemslist.splice(index, 1)




                    })
                .catch(error=> {console.log(error)
                this.isLoading=false;


                this.flashMessage.show({
                status: 'error',
                title: 'Erreur De Suppression',
                message: 'Impossible  de supprimer les données .. Réessayez !!'
                })
                });

        },
        add_click(button) {
        this.itemForm.id=''
        this.itemForm.code='--'
        this.itemForm.cin='--'
        this.itemForm.nom_prenom=''
        this.itemForm.tel=''
        this.itemForm.adresse=''
        this.itemForm.ice=''
        this.itemForm.etat=true
        this.itemForm.profession=''
        this.itemForm.garant=''
        this.itemForm.garant_infos=''
        this.itemForm.is_plaf=false
        this.itemForm.plafond=0

                   if(this.selected_zone==0)
                   {
                        this.itemForm.zone_id=''
                   }
                   else
                   {
                        this.itemForm.zone_id= this.selected_zone
                   }

        this.ModalEdit=false;
        this.ModalType='Ajouter'








        this.$root.$emit('bv::show::modal', 'banqModal', button)
      },
        edit_click(item, index, button) {

                this.$confirm("Voulez-vous vraiment modifier cet élément ??")
                 .then(() => {

                this.itemForm.id=item.id
                this.itemForm.code=item.code
                this.itemForm.cin=item.cin
                this.itemForm.nom_prenom=item.nom_prenom
                this.itemForm.tel=item.tel
                this.itemForm.adresse=item.adresse
                this.itemForm.ice=item.ice
                this.itemForm.etat=item.etat
                this.itemForm.zone_id=item.zone_id

                this.itemForm.profession=item.profession
                this.itemForm.garant=item.garant
                this.itemForm.garant_infos=item.garant_infos

                this.itemForm.is_plaf=item.is_plaf
                this.itemForm.plafond=item.plafond

                this.ModalEdit=true;
                this.ModalType='Modifier'

                this.$root.$emit('bv::show::modal', 'banqModal', button)
                })
                .catch(error=> {
                console.log(error)

                });


      },
        del_click(item) {

                this.$confirm("Voulez-vous vraiment supprimer cet élément ??")
                 .then(() => {
 
        this.itemForm.id=item.id
        this.itemForm.code=item.code
        this.itemForm.nom_prenom=item.nom_prenom
        this.itemForm.tel=item.tel
        this.itemForm.adresse=item.adresse
        this.itemForm.etat=item.etat
        this.itemForm.zone_id=item.zone_id

                                    this.banqueDell()
                                })
                                .catch(error=> {
                                console.log(error)

                                    });




      },
        handleSubmit() {
                this.isSubmitted = true;

                this.$v.$touch();
                if (this.$v.$invalid) {
                this.flashMessage.show({
                status: 'info',
                title: 'Vérifiez les données et Reésayez !!',
                })
                }
                else 
                {


                            if(this.ModalEdit)
                            {
                                this.banqueEdit()
                            }
                            else
                            {

                                this.itemslisttore()
                            }   

                }

            },

            fetchFormZones: function () {
                    //chager les categories:
                    this.isLoading=true;
                    this.$http.get('/zones')
                    .then((result) => {
                                this.zones = result.data.data
                                this.isLoading=false;
                    })
                    .catch(error=> {
                                console.log(error)
                                this.isLoading=false;
                                this.flashMessage.show({
                                status: 'error',
                                title: 'Erreur de Chargement',
                                message: 'Impossible charger les données.. Actualisez et Réessayez'
                                })
                    });
           }

    },
    components: {
              Loading      
    }
}
</script>

<style  scoped>

</style>