<template>
<div class="p-4 back_princ">

<div class=" d-flex justify-content-center var_color">
 <i class="fas fa-users fa-4x"></i>
</div>

<div class=" d-flex justify-content-center var_color mb-4">
 <h2 class="font-weight-lighter">Clients</h2>
</div>




<div v-if="d_gst_client_zon==true" class=" row p-4">

            <!-- Earnings (Monthly) Card Example -->
            <div class="col">
              <div class="btn card border border-light shadow h-100 py-2" @click="click_zones">
                <div class="card-body">
                  <div class="row no-gutters align-items-center">
                    <div class="col mr-2">
                      <div class="font-weight-bold text-secondary  text-uppercase ">Zones Clients</div>
                    </div>
                    <div class="col-auto">
                      <i class="fas fa-map-marked-alt fa-2x text-secondary"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>



</div>

<div v-if="d_gst_client_zon==true" class=" row p-4">
            <!-- Earnings (Annual) Card Example -->
            <div class="col">
              <div class="btn card border border-light shadow h-100 py-2"  @click="click_clients">
                <div class="card-body">
                  <div class="row no-gutters align-items-center">
                    <div class="col mr-2">
                      <div class="text-xs font-weight-bold text-secondary  text-uppercase">Gestion Des Clients</div>
                    </div>
                    <div class="col-auto">
                      <i class="fas fa-users fa-2x text-secondary"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>





</div>







  </div>
</template>

<script>
export default {
computed:{

      d_gst_client_zon() {
      return this.$store.getters.get_gst_client_zon;
    },

},
 methods:{

      click_zones()
      {
                       //goto first page:
                    this.$router.push({ 
                        name: 'ZonesList'

                    });
      },
      click_clients()
      {
                        //goto first page: 
                    this.$router.push({ 
                        name: 'clientsList'

                    });
      },

 }
}
</script>
<style scoped>
.back_princ{
  background: #eff7fa;
}
.var_color{
  color: #2faaa5;
}

.text_org{
  color: green;
}
</style>