<template>
<div>
  
                        

      <div class="rounded-lg shadow border border-white bg-white">
            <div class="row justify-content-centre align-items-center">    


            <h4 class="col m-3 font-weight-lighter text-info"> <span class="fa fa-file-alt"></span> Bons De Charge ( Réglés /Archivés )    </h4> 
  
 
                    <div class="col">

                <b-button pill size="sm"  class="m-1 float-right text-secondary" variant="link" @click="NewBon">
                            <i class="fa fa-home fa-2x"></i>
                </b-button>


                                    <b-button pill size="sm"  class="m-2 float-right" variant="info" @click="fetchList">
                                        <i class="fa fa-sync-alt"></i>
                                        <span> Actualiser</span> 
                                    </b-button>
                    </div>
            </div>
       



        <b-table 

        id="main-table"
        :per-page="perPage"
        :current-page="currentPage"
        responsive 
        sort-icon-left
        head-variant="light"

        :fields="fields" 
        :items="itemslist"
        >
            <template #cell(actions)="row">

                    <b-button v-if="d_gst_charg_vent==true" pill size="sm" class="mr-2 mt-1 mb-1  float-right" variant="outline-warning" @click="ventes_click(row.item)">
                            <i class="fa fa-file-export"></i>
                    
                    </b-button>
                </template>
        
        </b-table>

                <b-pagination
                pills
                v-model="currentPage"
                :total-rows="rows_pagin"
                :per-page="perPage"
                aria-controls="main-table"
                align="center"
                ></b-pagination>

      </div>




    <!-- les trucs public -->

    <loading :active.sync="isLoading" :can-cancel="false" :is-full-page="true" color="#0179F9" loader='bars'></loading>


</div>
</template>

<script>
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import {required} from "vuelidate/lib/validators";

export default {
    computed: {
      rows_pagin() {
        return this.itemslist.length
      },
        d_gst_charg_vent() {
      return this.$store.getters.get_gst_charg_vent;
    },

    user_dep() {

      if(this.$store.getters.get_user)
      {
            return this.$store.getters.get_user.dep;
      }
      else
      {
            return 0;
      }
    },
    },
    mounted(){
        this.fetchList()
    },
	data () {
		return {
            perPage: 20,
            currentPage: 1,
            itemslist: [],

            fields: [
				{key: 'nom', label: 'Nom', sortable: true},
                {key: 'date', label: 'Date', sortable: true},
                {key: 'zone', label: 'Zone', sortable: true},
                {key: 'vendeur', label: 'Vendeur', sortable: true},
                {key: 'camion', label: 'Camion', sortable: true},
                {key: 'depot', label: 'Dépôt', sortable: true},
                 {key: 'user', label: 'Utilisateur', sortable: true},
                { key: 'actions', label: '' }
            ],
            //public trucs

            isLoading: false
		}
	},
    validations: {
            itemForm: {
                nom: {
                    required
                }
            }
    },
    methods :{

        NewBon()
        {
                    this.$router.push({ 
                        name: 'menuCharg'
                    });
        },
              ventes_click(item) {

                this.$confirm("Voulez-vous vraiment Afficher les ventes de ce Bon / Dévalider ce Bon  ??")
                 .then(() => {


                        this.$router.push({ 
                        name: 'bonDecharge', 
                        params: { 
                        idZon: item.zone_id,
                        nZon: item.zone,
                        idCharg: item.id,
                        nCharg: item.nom,
                        nVend: item.vendeur,
                        id_Vend: item.vendeur_id,
                        idPrime:item.prim_client_id,
                        is_val:false,
                        is_stockval:item.stock_val               
                        } 
                    });

                })
                .catch(error=> {
                console.log(error)

                });


      },

        fetchList: function () {
                    this.isLoading=true;
                    this.$http.get('/bnChargeListType/1/1/'+this.user_dep)
                    .then((result) => {
                                this.itemslist = result.data.data
                                this.isLoading=false;
                    })
                    .catch(error=> {
                                console.log(error)
                                this.isLoading=false;
                                this.flashMessage.show({
                                status: 'error',
                                title: 'Erreur de Chargement',
                                message: 'Impossible charger les données.. Actualisez et Réessayez'
                                })
                    });
        },


    },
    components: {
              Loading      
    }
}
</script>

<style  scoped>

</style>