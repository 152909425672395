<template>
<div class="p-4 back_princ">

<div class=" d-flex justify-content-center text-info">
 <i class="fas fa-qrcode fa-4x"></i>
</div>

<div class=" d-flex justify-content-center text-info mb-4">
 <h2 class="font-weight-lighter">Code d'activation</h2>
</div>

                      <div class="mt-5 d-flex justify-content-center">
                      <vue-qrcode :value="d_qr_url" :options="{
                                              errorCorrectionLevel: 'H',
                                              width: 300,
                                              margin:1
                                            }"
                        ></vue-qrcode>
                      </div>

  </div>
</template>

<script>

import VueQrcode from '@chenfengyuan/vue-qrcode';


export default {
  computed:{

      d_qr_url() {
      return this.$store.getters.get_qr_url;
    },


  },
      mounted(){

        this.fetchFormZones()
    },
	data () {
		return {


 
		}
	},
 methods:{


code_gen()
{




},

 },
     components: { 
              VueQrcode
    }

}
</script>
<style scoped>
.back_princ{
  background: #eff7fa;
}

.text_org{
  color: green;
}
</style>