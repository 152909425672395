<template>
  
<div  class="card  shadow border border-white bg-white h-100 pb-3 overflow-auto">
        <div class="row pb-1 border-bottom border-info m-3">


                <h4 class="col font-weight-lighter text-info"> <span class="fa fa-folder-open"></span> Suivi Des Vendeurs</h4> 
                            
                    <div class="col">
         

                                    <b-button pill size="sm"  class="m-1 float-right text-secondary" variant="link" @click="NewBon">
                                                <i class="fa fa-home fa-2x"></i>
                                    </b-button>

                    </div>
        </div>

<div class="row h-100 m-2">
  
        <div class="col-4">

               <div class="card border-info h-100 overflow-auto">


        <form @submit.prevent="SubmitFtchClient()" class="bg-info">


                <div class="input-group  border border-info rounded bg-info p-1 col">

                                              <div class="input-group-prepend">
                                                <span class="input-group-text">De </span>
                                              </div>
                                <b-form-datepicker
                                class="mr-sm-0"
                                id="datepicker-date_debut"
                                placeholder="date"
                                v-model="itemFormBon.date_debut"
                                :date-format-options="{day: 'numeric' , month: 'numeric',year: 'numeric' }"
                                locale="fr" />    
                </div>
                <div class="input-group  border border-info rounded bg-info p-1 col">
                    
                            
                                <div class="input-group-prepend">
                                <span class="input-group-text">Au</span>
                                </div>
                                <b-form-datepicker
                                class="mr-sm-0"
                                id="datepicker-date_fin"
                                placeholder="date"
                                v-model="itemFormBon.date_fin"
                                :date-format-options="{day: 'numeric' , month: 'numeric',year: 'numeric' }"
                                locale="fr" />    
                </div>


                                                
                                   
        </form>



                            <div class="card-header  bg-info border-info p-1">
                                <b-form-input v-model="serchclient"  class="border-info " id="search-art" size="sm" placeholder="Chercher ..." type="search"></b-form-input>
                            
                                        <div class="custom-control custom-switch form-control-sm ml-1">
                                        <input type="checkbox"
                                        class="custom-control-input" 
                                        id="isCINBarreSearch"
                                                v-model="isCINBarreSearch"
                                                name="isCINBarreSearch"
                                                value=true
                                                unchecked-value=false>

                                        <label class="custom-control-label text-white  mb-0" for="isCINBarreSearch">Recherche Par Code</label>
                                        </div>
                            </div>

                            <b-list-group flush  >
                         
                            <b-list-group-item  v-for="item in filtervendeurs" :key="item.id" button class="p-3" variant="info" @click="client_click(item.id)">
                                 {{ item.nom_prenom }} -  {{ item.code }} 

                            </b-list-group-item>                          

                            </b-list-group>
               </div>
        </div> 


            <!-- LA PARTIE DES CARDES -->
        <div  class="col-8  mr-auto ">


          <div class="row mb-3">
            <div class="col">
              <div class="card border-left-success shadow h-100 py-1">
                <div class="card-body p-0">

                <div class="float-left pt-1 text-secondary">
                    <h5 class="font-weight-normal ml-2">{{itemFormBon.vendeur_nom}} - <span class="d-inline font-weight-light">De : {{itemFormBon.date_debut}} Au : {{itemFormBon.date_fin}} </span></h5>
                </div>



                </div>
              </div>
            </div>

          </div>


            <!-- LES CARTES DES CARTES -->
          <div class="row">

            <!-- Tasks Card Example -->
            <div class="col">
              <div class="card border-left-info shadow h-100 py-1">
                <div class="card-body">
                  <div class="row no-gutters align-items-center">
                    <div class="col">

                        <div class=" d-flex justify-content-center text-info">
                        <i class="fas fa-coins fa-2x" ></i>

                        </div>

                        <div class=" d-flex justify-content-center text-info mb-0">
                        <h5 class="font-weight-lighter">Chiffre D'Affaires</h5>
                        </div>

                        <div class=" d-flex justify-content-center p-1 mb-1 text-danger">
                                <h5 class="font-weight-normal">{{totauxClient.total_chifr}}  DH</h5>
                        </div>



                                <b-button  pill size="sm"  class="mt-1 btn-block p-2" variant="link" @click="detailChifr_click">
                                            <i class="fa fa-check"></i>
                                    <span>   Détails</span> 
                                </b-button>
                    </div>

                  </div>
                </div>
              </div>
            </div>

            <!-- Earnings (Annual) Card Example -->
            <div class="col">
              <div class="card border-left-success shadow h-100 py-1">
                <div class="card-body">
                  <div class="row no-gutters align-items-center">
                    <div class="col">


                        <div class=" d-flex justify-content-center text-info">
                        <i class="fas fa-reply fa-2x" ></i>

                        </div>

                        <div class=" d-flex justify-content-center text-info mb-0">
                        <h5 class="font-weight-lighter">Retour / Déchets</h5>
                        </div>
                                  
                      
                        <div class=" d-flex justify-content-center p-1 mb-1 text-danger">
                                <h5 class="font-weight-normal">{{totauxClient.total_retour}} DH</h5>
                        </div>
                                <b-button  pill size="sm"  class="mt-1 btn-block p-2" variant="link" @click="detailChifr_click">
                                            <i class="fa fa-check"></i>
                                    <span>   Détails</span> 
                                </b-button>
                    </div>
                  </div>
                </div>
              </div>
            </div>





          </div>
        
  <!-- Le pred de page -->



        <div class="row mt-3">
            <div class="col">
              <div class="card border-left-success shadow h-100 py-1">
                <div class="card-body p-0">


                        <div class=" d-flex justify-content-center text-info">
                        <i class="fas fa-address-book fa-2x" ></i>

                        </div>

                        <div class=" d-flex justify-content-center text-info mb-0">
                        <h5 class="font-weight-lighter">Crédits</h5>



                        </div>

                        <div class="row">


                                        <div class="col">
                                              <h5 class="ml-3 font-weight-lighter text-secondary">Total : {{total_montant}} DH</h5>
                                        </div>

                                        <div class="col ">
                                              <b-button    class="ml-1 mr-4 float-right p-0" variant="link" @click="fetchPaimentsClient(1)">
                                                          <i class="fa fa-sync-alt"></i>
                                                  <span> Payés </span> 
                                              </b-button>
                                        </div>
                        </div>
                                  <div class="col ">
                                            <b-table 
                                            sort-icon-left
                                            small 
                                            borderless
                                            striped 
                                            sticky-header
                                            :fields="fieldsPaieVnd" 
                                            :items="PaiementClient"
                                            >
                                                <template #cell(actions)="row">


                                                        <b-button pill size="sm"  class="mr-2 float-right" variant="outline-danger" @click="Dell_paimVC_click(row.item)">
                                                                    <i class="fa fa-trash-alt"></i>
                                                            
                                                        </b-button>


                                                        <b-button pill size="sm"  class="mr-2 float-right" variant="outline-info" @click="Valid_paimVC_click(row.item)">
                                                                    <i class="fa fa-sync-alt"></i>
                                                            
                                                        </b-button>
                                                </template>
                                            
                                            </b-table>
                                  </div>

                </div>
              </div>
            </div>

          </div>
        
        </div>   


</div> 



    <!-- le modal detail chifr -->
    <b-modal id="ChifrModal" size="lg" hide-footer>


       <b-table 
        small 
        borderless
        striped 
        sticky-header
        :fields="fieldsChifr" 
        :items="itemsDChifr"
        >

        
        </b-table>




    </b-modal>


    <!-- le modal -->
    <b-modal id="comptModal" title="Sélectionnez un compte"  hide-footer>
            <form @submit.prevent="handleSubmitCmp">
                            <div class="form-group">
                            <label for="bank_compte_id">Compte :</label>
                            <select class="form-control" id="bank_compte_id" v-model="itemFormBon.bank_compte_id">
                            <option v-for="item in comptes" :key="item.id" v-bind:value="item.id">   
                                {{ item.nom }}
                            </option>
                            </select>

            </div>


            <div class="form-group">
                <button type="submit" class="btn  btn-primary btn-block">Valider</button>
            </div>
            </form>  

    </b-modal>



      <loading :active.sync="isLoading" :can-cancel="false" :is-full-page="true" color="#0179F9" loader='bars'></loading>

 </div>



</template>

<script>
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import {required} from "vuelidate/lib/validators";


export default {
computed: {
          d_is_tresor() {
      return this.$store.getters.get_is_tresor;
      },
      user_name() {

      if(this.$store.getters.get_user)
      {
            return this.$store.getters.get_user.name;
      }
      else
      {
            return '';
      }
    },
      total_montant: function() {
      return this.PaiementClient.reduce((a, b) => +a + +b.montant, 0);
    },
       filtervendeurs() {
       return this.vendeursList.filter(clnt => {

            if(this.isCINBarreSearch)
            {
                if(clnt.code)
                {
                return clnt.code.toLowerCase().includes(this.serchclient.toLowerCase())
                }

            }
            else
            {

                if(clnt.nom_prenom)
                {
                return clnt.nom_prenom.toLowerCase().includes(this.serchclient.toLowerCase())
                }


            }

      }) 
    }
    },
    mounted(){
      this.fetchListVendeurs() 
    },
    data () {
		return {
            comptes:[],
            PaiementClient:[],
            fieldsPaieVnd: [
                {key: 'montant', label: ''},
                {key: 'infos_oper', label: ''},
                {key: 'date_ech', label: ''},
                {key: 'actions', label: '' }
            ],


        itemsDChifr: [],
        fieldsChifr: [
				{key: 'ref', label: 'Référence', sortable: true},
        {key: 'total_qte_vend', label: 'Quantité Ventes', sortable: true},
				{key: 'total_qte_retour', label: 'Quantité Retour', sortable: true},
        {key: 'total_vend', label: 'Ventes (DH)', sortable: true},
				{key: 'total_retour', label: 'Retour (DH)', sortable: true},

            ],
            itemFormBon: {

                        date_debut:new Date().toJSON().slice(0,10).replace(/-/g,'-'),
                        date_fin:new Date().toJSON().slice(0,10).replace(/-/g,'-'),
                        vendeur_id:'',
                        vendeur_nom:'',
                        bank_compte_id:'',
                        paiem_id:'',
                        date:new Date().toJSON().slice(0,10).replace(/-/g,'-'),
                        user:'user'
            },
            totauxClient: {
                    total_chifr:'0',
                    total_retour:'0',
            },
            vendeursList:[],
            isSubmitted: false,
            isLoading: false,
            isCINBarreSearch: false,
            serchclient:''
        }
        
        },
   validations: {
            itemFormBon: {
                date_debut: {
                    required
                },
                date_fin: {
                    required
                }

            }

    },
        methods:{ 

        SubmitFtchClient(){

                this.isSubmitted = true;

                this.$v.$touch();
                if (this.$v.$invalid) {
                this.flashMessage.show({
                status: 'info',
                title: 'Vérifiez les données et Reésayez !!',
                })
                }
                else 
                {


                this.fetchListVendeurs()
   

                }


        },
        fetchListVendeurs() {
                    this.isLoading=true;
                    this.$http.get('/vendeursMinActif')
                    .then((result) => {

                                this.vendeursList = result.data.data   
                                this.isLoading=false;

                    })
                    .catch(error=> {
                                console.log(error)
                                this.isLoading=false;
                                this.flashMessage.show({
                                status: 'error',
                                title: 'Erreur de Chargement',
                                message: 'Impossible charger les données.. Actualisez et Réessayez'
                                })
                    });
        },
         client_click(id_clt)
        {

            let clt = this.vendeursList.findIndex(item => item.id === id_clt)
            this.itemFormBon.vendeur_id=this.vendeursList[clt].id

            this.itemFormBon.vendeur_nom=this.vendeursList[clt].nom_prenom

         this.TotauxChifrRetour(this.itemFormBon.vendeur_id,this.itemFormBon.date_debut,this.itemFormBon.date_fin) 
         this.fetchPaimentsClient(0) 

          //  this.verifier_paiement(this.itemFormBon.agriculteur_id,this.itemFormBon.mois)

        }, 
        TotauxChifrRetour(id_clt,deb,fin) {
                    this.isLoading=true;
                    this.$http.get('/chifrClntVendrTotal/0/'+id_clt+'/'+deb+'/'+fin)
                    .then((result) => {

                               this.totauxClient.total_chifr = result.data.data[0].total_vend
                               this.totauxClient.total_retour = result.data.data[0].total_retour



                                    if(!this.totauxClient.total_chifr)
                                    {
                                    this.totauxClient.total_chifr=0
                                    }
                                    else
                                    {
                                      let number =Number(this.totauxClient.total_chifr)
                                      this.totauxClient.total_chifr = number.toFixed(2)
                                    }

                                    if(!this.totauxClient.total_retour)
                                    {
                                    this.totauxClient.total_retour=0
                                    }
                                    else
                                    {
                                      let number =Number(this.totauxClient.total_retour)
                                      this.totauxClient.total_retour = number.toFixed(2)
                                    }

                                    //fin calcul effacer loader
                                    this.isLoading=false;


                    })
                    .catch(error=> {
                                console.log(error)
                                this.isLoading=false;
                                this.flashMessage.show({
                                status: 'error',
                                title: 'Erreur de Chargement',
                                message: 'Impossible charger les données.. Actualisez et Réessayez'
                                })
                    });
        },
        detailChifr_click() {
                    this.isLoading=true;
                    this.$http.get('/qteClntVendrChifr/0/'+this.itemFormBon.vendeur_id+'/'+this.itemFormBon.date_debut+'/'+this.itemFormBon.date_fin)
                    .then((result) => {

                               this.itemsDChifr = result.data.data

                                    //fin calcul effacer loader
                                    this.isLoading=false;
                                    this.$root.$emit('bv::show::modal', 'ChifrModal')

                    })
                    .catch(error=> {
                                console.log(error)
                                this.isLoading=false;
                                this.flashMessage.show({
                                status: 'error',
                                title: 'Erreur de Chargement',
                                message: 'Impossible charger les données.. Actualisez et Réessayez'
                                })
                    });
        },



        fetchPaimentsClient(eta) {


              if(this.itemFormBon.vendeur_id)
              {
                                  //chager les zones:
                                  this.isLoading=true;
                                  this.$http.get('/paiemPrVendClint/0/'+this.itemFormBon.vendeur_id+'/'+eta)
                                  .then((result) => {
                                              this.PaiementClient = result.data.data





                                              this.isLoading=false;
                                  })
                                  .catch(error=> {
                                              console.log(error)
                                              this.isLoading=false;
                                              this.flashMessage.show({
                                              status: 'error',
                                              title: 'Erreur de Chargement',
                                              message: 'Impossible charger les données.. Actualisez et Réessayez'
                                              })
                                  });
              }


        },

      Valid_paimVC_click(item) {
                                this.$confirm("Voulez-vous changer l'etat de ce paiement ??")
                                .then(() => {




                                if(this.d_is_tresor==false)
                                {
                                     


                                //intit champ:
                                this.itemFormBon.bank_compte_id=-1
                                this.itemFormBon.paiem_id = item.id

                                let index = this.PaiementClient.findIndex(ite => ite.id === item.id)

                                this.PaiementClient.splice(index, 1)



                                this.handleSubmitCmp()

                                }
                                else
                                {  

            //comptes :
                    this.isLoading=true;
                    this.$http.get('/bankComptes')
                    .then((result) => {
                                this.comptes = result.data.data
                                this.isLoading=false;

                                //intit champ:
                                this.itemFormBon.bank_compte_id=''
                                this.itemFormBon.paiem_id = item.id

                                let index = this.PaiementClient.findIndex(ite => ite.id === item.id)

                                this.PaiementClient.splice(index, 1)

                                this.$root.$emit('bv::show::modal', 'comptModal')
                    })
                    .catch(error=> {
                                console.log(error)
                                this.isLoading=false;
                                this.flashMessage.show({
                                status: 'error',
                                title: 'Erreur de Chargement',
                                message: 'Impossible charger les données.. Actualisez et Réessayez'
                                })
                    });
                                }


                                })
                                .catch(error=> {
                                console.log(error)

                                    });








        },
        handleSubmitCmp() {

            if(this.itemFormBon.bank_compte_id)
            {

              //HUNDEL USER
this.itemFormBon.user = this.user_name

            this.isLoading=true;

            this.$http.get('/validPaiClVend/'+this.itemFormBon.paiem_id+'/'+this.itemFormBon.bank_compte_id+'/'+this.itemFormBon.date+'/'+this.itemFormBon.user)
                .then(response => {

                console.log(response.data)

                this.isLoading=false;   
                this.flashMessage.show({
                    status: 'success',
                    title: 'Données sauvegardées',
                    message: 'Les données sont sauvegardées correctement '
                })
                  
                })
                .catch(error=> {console.log(error)
                this.isLoading=false;


                this.flashMessage.show({
                status: 'error',
                title: 'Erreur De Sauvegarde',
                message: 'Impossible  de sauvegarder les données .. Réessayez !!'
                })
                });













               // this.annulPaiem()

                this.$bvModal.hide('comptModal')
            }
            else
            {
                this.flashMessage.show({
                status: 'info',
                title: 'Sélectionnez un compte !!',
                })

            }
        },
        Dell_paimVC_click(itemA)
        {
                this.isLoading=true;

                this.$http.delete('/paiemVendClients/'+itemA.id)
                    .then(response => {
                    console.log(response.data)
                    this.isLoading=false;   
                    this.flashMessage.show({
                    status: 'success',
                    title: 'Données Supprimées',
                    message: 'Les données sont supprimées correctement'
                    })
                        let index = this.PaiementClient.findIndex(item => item.id === itemA.id)

                        this.PaiementClient.splice(index, 1)
                    })
                    .catch(error=> {console.log(error)
                    this.isLoading=false;


                    this.flashMessage.show({
                    status: 'error',
                    title: 'Erreur De Suppression',
                    message: 'Impossible  de supprimer les données .. Réessayez !!'
                    })
                    });
          
        },
                NewBon()
        {
                    this.$router.push({ 
                        name: 'menuClientVend'
                    });
        },
        },
components: {
              Loading      
}
}
</script>

<style>
.BgSearch {
  background-color:#BEE5EB;
  opacity: 0.9;
}
</style>