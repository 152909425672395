<template>
  

<div  class="card  shadow border border-white bg-white h-100 pb-3 overflow-auto">


        <div class="row pb-1 border-bottom border-info m-3">


                <h4 class="font-weight-lighter text-info"> <span class="fa fa-file-alt"></span> Bon De Charge</h4> 
            
        </div>

          <div class="row  h-100 m-1">

        <div class="col-md-8 ml-auto mr-auto">

           <form @submit.prevent="handleSubmitBonCMD()">

            <div class="row  mt-2">
            <div class="form-group col">
                <label for="qte">Bon :</label>
                <input :disabled="nom_dis" type="text" v-model="itemFormBon.nom" id="qte" name="qte" class="form-control"
                 :class="{ 'is-invalid': isSubmitted && $v.itemFormBon.nom.$error }"/>
                 
                <div v-if="isSubmitted && !$v.itemFormBon.nom.required" class="invalid-feedback">Ce champ est obligatoire !!</div>


            </div>


            <div class="form-group col">
                <label for="date_exp" class="mr-sm-2">Date :</label>
                    <b-form-datepicker
                    class="mr-sm-2"
                    id="datepicker-date_exp"
                    placeholder="date"
                    v-model="itemFormBon.date"
                    :date-format-options="{day: 'numeric' , month: 'numeric',year: 'numeric' }"
                    locale="fr" />
            </div>


            </div>



            <div class="row mt-2">
                        <div class="form-group col">

                            <label for="zone_id">Zone :</label>
                            <select  class="form-control" id="zone_id" v-model="itemFormBon.zone_id"
                            :class="{ 'is-invalid': isSubmitted && $v.itemFormBon.zone_id.$error }">
                            <option v-for="item in zones" :key="item.id" v-bind:value="item.id">   
                                {{ item.nom }}
                            </option>

                            </select>

                             <div v-if="isSubmitted && !$v.itemFormBon.zone_id.required" class="invalid-feedback">Ce champ est obligatoire !!</div>

            </div>
            <div class="form-group col">

                            <label for="depot_id">Dépôt :</label>
                            <select :disabled="!nom_dis" class="form-control" id="depot_id" v-model="itemFormBon.depot_id"
                            :class="{ 'is-invalid': isSubmitted && $v.itemFormBon.depot_id.$error }">
                            <option v-for="item in depots" :key="item.id" v-bind:value="item.id">   
                                {{ item.nom }}
                            </option>

                            </select>

                             <div v-if="isSubmitted && !$v.itemFormBon.depot_id.required" class="invalid-feedback">Ce champ est obligatoire !!</div>

            </div>
            </div>


            <div class="row mt-2">
            <div class="form-group col">

                            <label for="vendeur_id">Vendeur :</label>
                            <select  @change="chang_camionVentr" class="form-control" id="vendeur_id" v-model="itemFormBon.vendeur_id"
                            :class="{ 'is-invalid': isSubmitted && $v.itemFormBon.vendeur_id.$error }">
                            <option v-for="item in vendeurs" :key="item.id" v-bind:value="item.id">   
                                {{ item.nom_prenom }} - {{ item.code }} 
                            </option>

                            </select>

                             <div v-if="isSubmitted && !$v.itemFormBon.vendeur_id.required" class="invalid-feedback">Ce champ est obligatoire !!</div>

            </div>
            <div class="form-group col">

                            <label for="camion_id">Camion :</label>
                            <select  class="form-control" id="camion_id" v-model="itemFormBon.camion_id"
                            :class="{ 'is-invalid': isSubmitted && $v.itemFormBon.camion_id.$error }">
                            <option v-for="item in camions" :key="item.id" v-bind:value="item.id">   
                                {{ item.matricul }}
                            </option>

                            </select>

                             <div v-if="isSubmitted && !$v.itemFormBon.camion_id.required" class="invalid-feedback">Ce champ est obligatoire !!</div>

            </div>
            </div>

            <div class="form-group">

                            <label for="prim_client_id">Primes Clients :</label>
                            <select class="form-control" id="prim_client_id" v-model="itemFormBon.prim_client_id"
                            :class="{ 'is-invalid': isSubmitted && $v.itemFormBon.prim_client_id.$error }">
                            <option v-for="item in primes" :key="item.id" v-bind:value="item.id">   
                                {{ item.nom }} - {{item.zone}} - {{item.date}}
                            </option>


                            <option value=0>Aucune</option>

                            </select>

                             <div v-if="isSubmitted && !$v.itemFormBon.prim_client_id.required" class="invalid-feedback">Ce champ est obligatoire !!</div>

            </div>

            <div class="form-group mt-2">
            <label for="adresse">Note</label>
            <textarea name="adresse" id="adresse"  rows="2" class="form-control" v-model="itemFormBon.description"></textarea>
            </div>


            <div class="form-group">
                <button type="submit" class="btn  btn-primary btn-block">Suivant</button>
            </div>

        </form>



        </div>   

</div> 

    
      <loading :active.sync="isLoading" :can-cancel="false" :is-full-page="true" color="#0179F9" loader='bars'></loading>

 </div>






  
</template>

<script>
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import {required} from "vuelidate/lib/validators";

export default {
 props: ['ResivedId'],
 computed: {
    user_name() {

      if(this.$store.getters.get_user)
      {
            return this.$store.getters.get_user.name;
      }
      else
      {
            return '';
      }
    },
    user_dep() {

      if(this.$store.getters.get_user)
      {
            return this.$store.getters.get_user.dep;
      }
      else
      {
            return 0;
      }
    },

  },
mounted(){

this.fetchzonesDepPrimVendr()

if(this.ResivedId)
{
this.process_modification(this.ResivedId)
}
else
{
this.process_Ajouter()
}

    },
data () {
		return {
            vendeurs:[],
            camions:[],
            primes:[],
            zones:[],
            itemFormBon: {
                    id:"",
                    nom:'___',
                    date:new Date().toJSON().slice(0,10).replace(/-/g,'-'),
                    valider:false,
                    description:'',
                    prim_client_id:0,
                    vendeur_id:'',
                    camion_id:'',
                    zone_id:'',
                    depot_id:'',
                    user:'user'},
            depots:[],
            isSubmitted: false,
            isLoading: false,
            isModification:false,


            //boninfos Champs disibl:
            nom_dis:true,
        


		}
    },
    validations: {
            itemFormBon: {
                nom: {
                    required
                },
                date: {
                    required
                },
                valider: {
                    required
                },
                prim_client_id: {
                    required
                },
                vendeur_id: {
                    required
                },
                camion_id: {
                    required
                },
                zone_id: {
                    required
                },
                depot_id: {
                    required
                },
                user: {
                    required
                }
            }

    },
methods:{
        
        chang_camionVentr()
        {
                            //set nom lot
                if(this.itemFormBon.vendeur_id)
                {
                let am = this.camions.findIndex(item => item.vendeur_id === this.itemFormBon.vendeur_id)
                this.itemFormBon.camion_id= this.camions[am].id
                }
        },

        process_modification(id_resived)
        {
                    this.isLoading=true;
                    this.$http.get('/bnCharges/'+id_resived)
                    .then((result) => {
                    
                    //affectation des information
                    this.itemFormBon.id=result.data.data.id,
                    this.itemFormBon.nom=result.data.data.nom,
                    this.itemFormBon.date=result.data.data.date,
                    this.itemFormBon.valider=result.data.data.valider,
                    this.itemFormBon.description=result.data.data.description,
                    this.itemFormBon.prim_client_id=result.data.data.prim_client_id,
                    this.itemFormBon.vendeur_id=result.data.data.vendeur_id,
                    this.itemFormBon.camion_id=result.data.data.camion_id,
                    this.itemFormBon.zone_id=result.data.data.zone_id,
                    this.itemFormBon.depot_id=result.data.data.depot_id,
                    this.itemFormBon.user=result.data.data.user




                    //boninfos Champs disibl:
                        this.nom_dis=false,
                     


                     //Activer la modification
                     this.isModification=true,


                     //desible load
                     this.isLoading=false;
                    })
                    .catch(error=> {
                                console.log(error)
                                this.isLoading=false;
                                this.flashMessage.show({
                                status: 'error',
                                title: 'Erreur de Chargement',
                                message: 'Impossible charger les données.. Actualisez et Réessayez'
                                })
                    });

        },
        process_Ajouter()
        {

                    //boninfos Champs disibl:
                        this.nom_dis=true,
              

                     //Activer la modification
                     this.isModification=false

        },
        fetchzonesDepPrimVendr: function () {

                    //chager les zones:
                    this.isLoading=true;
                    this.$http.get('/zones')
                    .then((result) => {
                                this.zones = result.data.data
                                this.isLoading=false;
                    })
                    .catch(error=> {
                                console.log(error)
                                this.isLoading=false;
                                this.flashMessage.show({
                                status: 'error',
                                title: 'Erreur de Chargement',
                                message: 'Impossible charger les données.. Actualisez et Réessayez'
                                })
                    });

                    //chager les depots:
                    this.isLoading=true;
                    this.$http.get('/depotPerson/'+this.user_dep)
                    .then((result) => {
                                this.depots = result.data.data
                                this.isLoading=false;
                    })
                    .catch(error=> {
                                console.log(error)
                                this.isLoading=false;
                                this.flashMessage.show({
                                status: 'error',
                                title: 'Erreur de Chargement',
                                message: 'Impossible charger les données.. Actualisez et Réessayez'
                                })
                    });

                    //chager les depots:
                    this.isLoading=true;
                    this.$http.get('/vendeursMinActif')
                    .then((result) => {
                                this.vendeurs = result.data.data
                                this.isLoading=false;
                    })
                    .catch(error=> {
                                console.log(error)
                                this.isLoading=false;
                                this.flashMessage.show({
                                status: 'error',
                                title: 'Erreur de Chargement',
                                message: 'Impossible charger les données.. Actualisez et Réessayez'
                                })
                    });

                    //chager les camions:
                    this.isLoading=true;
                    this.$http.get('/camions')
                    .then((result) => {
                                this.camions = result.data.data
                                this.isLoading=false;
                    })
                    .catch(error=> {
                                console.log(error)
                                this.isLoading=false;
                                this.flashMessage.show({
                                status: 'error',
                                title: 'Erreur de Chargement',
                                message: 'Impossible charger les données.. Actualisez et Réessayez'
                                })
                    });

                    //chager les primes:
                    this.isLoading=true;
                    this.$http.get('/primClientType/0/'+this.user_dep)
                    .then((result) => {
                                this.primes = result.data.data
                                this.isLoading=false;
                    })
                    .catch(error=> {
                                console.log(error)
                                this.isLoading=false;
                                this.flashMessage.show({
                                status: 'error',
                                title: 'Erreur de Chargement',
                                message: 'Impossible charger les données.. Actualisez et Réessayez'
                                })
                    });
        },
        handleSubmitBonCMD(){

                //USER HANDLE
                this.itemFormBon.user= this.user_name

                this.isSubmitted = true;

                this.$v.$touch();
                if (this.$v.$invalid) {
                this.flashMessage.show({
                status: 'info',
                title: 'Vérifiez les données et Reésayez !!',
                })
                }
                else 
                {


                    if(this.isModification)
                    {
                
                    this.edit_bon()
                    }
                    else
                    {
                    
                    this.add_bon()
                    }
   

                }


        },
        add_bon()
        {

            this.isLoading=true;

            this.$http.post('/bnCharges',{

                    nom:this.itemFormBon.nom,
                    date:this.itemFormBon.date,
                    valider:0,
                    description:this.itemFormBon.description,
                    prim_client_id:this.itemFormBon.prim_client_id,
                    vendeur_id:this.itemFormBon.vendeur_id,
                    camion_id:this.itemFormBon.camion_id,
                    zone_id:this.itemFormBon.zone_id,
                    depot_id:this.itemFormBon.depot_id,
                    user:this.itemFormBon.user

                })
                .then(response => {
                    //metre a jour id
                    this.itemFormBon.id=response.data.data.id,
                    this.itemFormBon.nom=response.data.data.nom,

                    //boninfos Champs disibl:
                    this.nom_dis=false,
          


                    //Activer la modification
                    this.isModification=true,


                    this.isLoading=false;
                    //afficher message
                    this.flashMessage.show({
                    status: 'success',
                    title: 'Données sauvegardées',
                    message: 'Les données sont sauvegardées correctement '


                })

                //afficahge detail


                  let dest = this.zones.findIndex(item => item.id === response.data.data.zone_id)
                    let dep = this.depots.findIndex(item => item.id === response.data.data.depot_id)
                    let vend = this.vendeurs.findIndex(item => item.id === response.data.data.vendeur_id)

                    this.preparerDetail(
                    response.data.data.id,
                    response.data.data.nom,
                    this.zones[dest].nom,
                    response.data.data.depot_id,
                    this.depots[dep].nom,
                    this.vendeurs[vend].nom_prenom,
                    response.data.data.camion_id,
                    response.data.data.prim_client_id,
                    response.data.data.vendeur_id

                    )


                })
                .catch(error=> {console.log(error)
                this.isLoading=false;


               this.flashMessage.show({
                status: 'error',
                title: 'Erreur De Sauvegarde',
                message: 'Impossible  de sauvegarder les données .. Réessayez !!'
                })
                });


        },
        edit_bon()
        {

            this.isLoading=true;

            this.$http.patch('/bnCharges/'+this.itemFormBon.id,{

                    nom:this.itemFormBon.nom,
                    date:this.itemFormBon.date,
                    valider:0,
                    description:this.itemFormBon.description,
                    prim_client_id:this.itemFormBon.prim_client_id,
                    vendeur_id:this.itemFormBon.vendeur_id,
                    camion_id:this.itemFormBon.camion_id,
                    zone_id:this.itemFormBon.zone_id,
                    depot_id:this.itemFormBon.depot_id,
                    user:this.itemFormBon.user
                })
                .then(response => {

                    this.isLoading=false;
                    console.log(response.data.data);


                    //afficher message
                    this.flashMessage.show({
                    status: 'success',
                    title: 'Données sauvegardées',
                    message: 'Les données sont sauvegardées correctement '
                })

                //afficahge detail

                  let dest = this.zones.findIndex(item => item.id === response.data.data.zone_id)
                    let dep = this.depots.findIndex(item => item.id === response.data.data.depot_id)
                    let vend = this.vendeurs.findIndex(item => item.id === response.data.data.vendeur_id)

                    this.preparerDetail(
                    response.data.data.id,
                    response.data.data.nom,
                    this.zones[dest].nom,
                    response.data.data.depot_id,
                    this.depots[dep].nom,
                    this.vendeurs[vend].nom_prenom,
                    response.data.data.camion_id,
                    response.data.data.prim_client_id,
                    response.data.data.vendeur_id
                    )

                })
                .catch(error=> {console.log(error)
                this.isLoading=false;


               this.flashMessage.show({
                status: 'error',
                title: 'Erreur De Sauvegarde',
                message: 'Impossible  de sauvegarder les données .. Réessayez !!'
                })
                });


        },
        preparerDetail(id_bon,nom,nZon,id_dep,n_dep,n_vend,idCam,idprm,idvnd)
        {
                     this.$router.push({ 
                        name: 'bonChargeDetail', 
                        params: { 
                        idBon: id_bon,
                        nomBon: nom,
                        nom_zon: nZon,
                        idDep: id_dep,
                        nDep: n_dep,
                        nVend:n_vend,
                        idCamion:idCam,
                        idPrim:idprm,
                        idVend:idvnd
                        } 
                            });
        }

        



},
components: {
              Loading      
}


}
</script>

<style>

</style>