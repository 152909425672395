<template>
<div class="p-4 back_princ">

<div class=" d-flex justify-content-center text-info">
 <i class="fas fa-truck-moving fa-4x"></i>
</div>

<div class=" d-flex justify-content-center text-info mb-4">
 <h2 class="font-weight-lighter">Camions / Plateuax</h2>
</div>




<div class=" row p-4">

            <!-- Earnings (Monthly) Card Example -->
            <div class="col">
              <div class="btn card border border-light shadow h-100 py-2" @click="click_camion">
                <div class="card-body">
                  <div class="row no-gutters align-items-center">
                    <div class="col mr-2">
                      <div class="text-xs font-weight-bold text-secondary text-uppercase">Listes Des Camions</div>
                    </div>
                    <div class="col-auto">
                      <i class="fas fa-truck-moving fa-2x text-secondary"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>

</div>





<div class=" row p-4">

            <!-- Earnings (Monthly) Card Example -->
            <div class="col">
              <div class="btn card border border-light shadow h-100 py-2" @click="click_gazoil">
                <div class="card-body">
                  <div class="row no-gutters align-items-center">
                    <div class="col mr-2">
                      <div class="font-weight-bold text-secondary  text-uppercase ">Km / Gazoil</div>
                    </div>
                    <div class="col-auto">
                      <i class="fas fa-gas-pump fa-2x text-secondary"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <!-- Earnings (Annual) Card Example -->
            <div class="col">
              <div class="btn card border border-light shadow h-100 py-2"  @click="click_maintenance">
                <div class="card-body">
                  <div class="row no-gutters align-items-center">
                    <div class="col mr-2">
                      <div class="text-xs font-weight-bold text-secondary  text-uppercase">Maintenance</div>
                    </div>
                    <div class="col-auto">
                      <i class="fas fa-tools fa-2x text-secondary"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>

</div>


<div class=" row p-4">

            <!-- Earnings (Monthly) Card Example -->
            <div class="col">
              <div class="btn card border border-light shadow h-100 py-2" @click="click_boites">
                <div class="card-body">
                  <div class="row no-gutters align-items-center">
                    <div class="col mr-2">
                      <div class="text-xs font-weight-bold text-secondary text-uppercase">Boites / Plateaux</div>
                    </div>
                    <div class="col-auto">
                      <i class="fas fa-inbox fa-2x text-secondary"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>


</div>

  </div>
</template>

<script>
export default {

 methods:{
       click_camion()
      {
                      //goto first page:
                    this.$router.push({ 
                        name: 'listCamion'

                    });
      },
      click_gazoil()
      {
                       //goto first page:
                    this.$router.push({ 
                        name: 'listKilomCamion'

                    });
      },
      click_maintenance()
      {
                        //goto first page:
                    this.$router.push({ 
                        name: 'maitenances'

                    });
      },
      click_boites()
      {
                        //goto first page:
                    this.$router.push({ 
                        name: 'boites'

                    });
      },

 }
}
</script>
<style scoped>
.back_princ{
  background: #eff7fa;
}

.text_org{
  color: green;
}
</style>