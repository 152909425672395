<template>
<div class="p-4 back_princ">

<div class=" d-flex justify-content-center text-info">
 <i class="fas fa-users fa-4x"></i>
</div>

<div class=" d-flex justify-content-center text-info mb-4">
 <h2 class="font-weight-lighter">Vendeurs / Suivis</h2>
</div>




<div  v-if="d_gst_vendr==true" class=" row p-4">

            <!-- Earnings (Monthly) Card Example -->
            <div class="col">
              <div class="btn card border border-light shadow h-100 py-2" @click="click_vendeurs">
                <div class="card-body">
                  <div class="row no-gutters align-items-center">
                    <div class="col mr-2">
                      <div class="text-xs font-weight-bold text-secondary text-uppercase">Gestion Des Vendeurs</div>
                    </div>
                    <div class="col-auto">
                      <i class="fas fa-people-arrows fa-2x text-secondary"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            
            <!-- Earnings (Monthly) Card Example -->
            <div class="col">
              <div class="btn card border border-light shadow h-100 py-2" @click="click_suivi_vend">
                <div class="card-body">
                  <div class="row no-gutters align-items-center">
                    <div class="col mr-2">
                      <div class="font-weight-bold text-secondary  text-uppercase ">Suivi Des Vendeurs</div>
                    </div>
                    <div class="col-auto">
                      <i class="fas fa-folder-open fa-2x text-secondary"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>

</div>


<div v-if="d_gst_client_zon==true" class=" row p-4">


            <!-- Earnings (Monthly) Card Example -->
            <div class="col">
              <div class="btn card border border-light shadow h-100 py-2" @click="click_suivi_cl">
                <div class="card-body">
                  <div class="row no-gutters align-items-center">
                    <div class="col mr-2">
                      <div class="font-weight-bold text-secondary  text-uppercase ">Suivi Des Clients (Distribution)</div>
                    </div>
                    <div class="col-auto">
                      <i class="fas fa-folder-open fa-2x text-secondary"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>



</div>



<div  class=" row p-4">

            <!-- Earnings (Monthly) Card Example -->
            <div v-if="d_gst_activ_qr==true"  class="col">
              <div class="btn card border border-light shadow h-100 py-2" @click="click_qr">
                <div class="card-body">
                  <div class="row no-gutters align-items-center">
                    <div class="col mr-2">
                      <div class="text-xs font-weight-bold text-secondary text-uppercase">Activation De L'Application Vendeurs</div>
                    </div>
                    <div class="col-auto">
                      <i class="fas fa-mobile-alt fa-2x text-secondary"></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>


</div>


  </div>
</template>

<script>
export default {
computed:{

    d_gst_client_zon() {
      return this.$store.getters.get_gst_client_zon;
    },
    d_gst_vendr() {
      return this.$store.getters.get_gst_vendr;
    },
    d_gst_activ_qr() {
      return this.$store.getters.get_gst_activ_qr;
    },
},
 methods:{
       click_vendeurs()
      {
                      //goto first page:
                    this.$router.push({ 
                        name: 'vendeursList'

                    });
      },
      click_zones()
      {
                       //goto first page:
                    this.$router.push({ 
                        name: 'ZonesList'

                    });
      },
      click_clients()
      {
                        //goto first page: 
                    this.$router.push({ 
                        name: 'clientsList'

                    });
      },
      click_suivi_cl()
      {
                        //goto first page: 
                    this.$router.push({ 
                        name: 'suiviClients'

                    });
      },
      click_suivi_vend()
      {
                        //goto first page: 
                    this.$router.push({ 
                        name: 'suiviVendeurs'

                    });
      },
      click_qr()
      {
                        //goto first page: 
                    this.$router.push({ 
                        name: 'qrActivation'

                    });
      },

      
 }
}
</script>
<style scoped>
.back_princ{
  background: #eff7fa;
}

.text_org{
  color: green;
}
</style>